// $(function() {
//   $.scrollUp({
//     scrollText: '',
//     scrollSpeed: 500
//   });
// });

$(function() {
  // 针对IE8中banner高度变化，修正background-size:cover的behavior
  $('#section0 .figure .banner').resize(function(e) {
    $(this).attr('class', 'banner');
  });
});
